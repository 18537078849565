import React from "react"
import carriersHeader from "../../../assets/images/products/netversys/carriers_header.svg"
import wmCarrierSelect from "../../../assets/images/products/netversys/wm-carrierselect.svg"
import multiCarrier from "../../../assets/images/products/netversys/multicarrier.svg"
import Sidebar from "./Sidebar"
import { Col, Container, Row } from "react-bootstrap"
import { useStaticQuery, graphql, Link } from "gatsby"
import WichtigeMerkmale from "../WichtigeMerkmale"
import LexikonBadgesRandomSection from "../../lexikon/LexikonBadgesRandomSection"

const Carrierauswahl = () => {

  const data = useStaticQuery(graphql`
          query CarrierauswahlQuery {
            carriers: allMarkdownRemark(filter: {fields: {collection: {eq: "carriers"}}}) {
              edges {
                node {
                  id
                  frontmatter {
                    active
                    carrier_support_email
                    carrier_name
                  }
                  fields {
                    collection
                    slug
                  }
                }
              }
            }
          }`)

  return (
    <>
      <section className="services-details-area ptb-100">
        <Container className="mt-5">
          <Row>
            <Col lg={8} md={12}>
              <div className="services-details-image">
                <img src={carriersHeader} alt="Multicarrier System" />
              </div>

              <div className="services-details-desc">
                <span className="sub-title">NETVERSYS</span>
                <h1 className={"pt-3"}>Regelbasierte Carrierauswahl</h1>
                <p>
                  Next Day Delivery mit DHL oder der 12 Uhr Versand mit DPD? Welche Variante bietet den besseren
                  Staffelpreis in Ihrem Tarif? NETVERSYS macht die Carrierauswahl einfach, da es durch voreingestellte
                  Regeln keine manuelle Auswahl mehr geben muss. Das System sucht anhand von definierten Regeln für die
                  aktuelle Sendung den am besten geeigneten Carrier. Das senkt die Fehlerquote und erhöht die
                  Geschwindigkeit am Versandplatz. Das passende Versandlabel des ausgewählten Frachtführers wird von
                  NETVERSYS automatisch gedruckt.
                </p>


                <WichtigeMerkmale items={[
                  "Entgelt-optimiert",
                  "Kundenwunsch",
                  "Abgleich gewichtsbezogener Staffelpreise",
                  "Qualitätsindikator des Carriers",
                  "Termin-/Zeitzustellung",
                  "weitere nach Wunsch möglich"
                ]} image={<img src={wmCarrierSelect}
                               alt="Carrierauswahl Merkmale"
                               className={"ml-lg-4 ml-0"} />} />

                <h3>Relevantes aus dem Lexikon</h3>

                <LexikonBadgesRandomSection />
              </div>
            </Col>

            <Col lg={4} md={12}>
              <Sidebar />
            </Col>
          </Row>
        </Container>
      </section>

      <section className="services-area pt-4 pb-70 bg-f1f8fb">
        <Container>
          <span className="mb-2 text-primary"><i className={"bx bxs-bullseye"} /> im Detail</span>
          <h2>Was Multicarrier bedeutet</h2>
          <Row className={"mt-5"}>
            <Col md={6} xs={12}>
              <img src={multiCarrier} alt={"Multicarrier"} />
            </Col>
            <Col md={{ offset: 1, span: 5 }} xs={{ offset: 1, span: 11 }}>
              <p>
                Die primäre Aufgabe einer Versandsoftware ist die Anbindung an verschiedene Informationssysteme und
                Schnittstellen eines Carriers. Dabei gilt es die Übergabe von Sendungsdaten über moderne
                Webservice-Schnittstellen zu ermöglichen, sowie es verschiedene EDI Standards einzuhalten um den
                traditionellen Weg anzubieten. Darüber hinaus bietet das System die Möglichkeit Track & Trace
                Informationen vom Frachtführer zu beziehen und diese für die Optimierung Ihres Versandprozesses zu
                nutzen.
              </p>
              <p>
                Mit dem Carrierselektor auf der Produktseite von NETVERSYS bietet LOGENTIS einen Service, um mit einem
                Klick die etablierten Versandprodukteeinzusehen. Außerdem können Sie allgemeine Informationen der
                Logistikdienstleister einsehen, die bereits in Netversys integriert sind..
              </p>
              <p>
                Folgende Carrier sind aktuell in NETVERSYS implementiert:&nbsp;
                {data.carriers.edges.map(({ node: carrier }, i) => (
                  <span key={i}>
                      &nbsp;
                    {carrier.frontmatter.active &&
                    <Link
                      to={"/versandsoftware" + carrier.fields.slug}>{carrier.frontmatter.carrier_name}</Link>
                    }
                    {!carrier.frontmatter.active &&
                    carrier.frontmatter.carrier_name
                    }
                    {i + 1 !== data.carriers.edges.length ? ", " : ""}
                  </span>
                ))}
              </p>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  )
}

export default Carrierauswahl

import React from "react"
import Layout from "../../../components/App/Layout"
import Navbar from "../../../components/App/Navbar"
import Footer from "../../../components/App/Footer"
import Carrierauswahl from "../../../components/ProductDetails/netversys/Carrierauswahl"
import Seo from "../../../components/App/Seo"

const CarrierauswahlPage = () => {
  return (
    <Layout>
      <Seo title={"Regelbasierte und effiziente Carrierauswahl"}
           description={"Next Day Delivery mit DHL oder der 12 Uhr Versand mit " +
           "DPD? NETVERSYS hilft bei der Selektion von Produkten und Carriern."}
           image={"/images/og/netversys-main-og.png"}>
            <script type="application/ld+json">{`
                  {
                    "@context": "https://schema.org",
                    "@type": "BreadcrumbList",
                    "itemListElement": [{
                      "@type": "ListItem",
                      "position": 1,
                      "name": "Home",
                      "item": "https://logentis.de"
                    },{
                      "@type": "ListItem",
                      "position": 2,
                      "name": "Versandsoftware",
                      "item": "https://logentis.de/versandsoftware/"
                    },{
                      "@type": "ListItem",
                      "position": 3,
                      "name": "Carrierauswahl",
                      "item": "https://logentis.de/versandsoftware/netversys/carrierauswahl/"
                    }]
                  }
                  `}
            </script>
      </Seo>
      <Navbar />
      <Carrierauswahl />
      <Footer />
    </Layout>
  );
}

export default CarrierauswahlPage
